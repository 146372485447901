@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.carousel-product {
  background-color: transparent;
  margin: 0;
  margin-left: -1px; }
  .carousel-product .slick-track {
    display: flex;
    height: 100%; }
  .carousel-product .slick-slide {
    float: none;
    height: auto; }
    .carousel-product .slick-slide:nth-child(1n) {
      border-left: 1px solid #CCCCCC; }
    .carousel-product .slick-slide .slick-slide > div {
      height: 100%; }
      .carousel-product .slick-slide .slick-slide > div .myItemClass {
        height: 100%; }
  .carousel-product .product-tile {
    border: none; }
    .carousel-product .product-tile .tile-body .pdp-link {
      line-height: 17px;
      padding-right: 5%; }
      @media (max-width: 839.98px) {
        .carousel-product .product-tile .tile-body .pdp-link {
          margin-right: 0; } }
      .carousel-product .product-tile .tile-body .pdp-link a {
        font-size: 13px;
        line-height: 17px;
        text-transform: uppercase; }
    .carousel-product .product-tile .image-container {
      display: flex;
      align-items: center;
      justify-content: center; }
      .carousel-product .product-tile .image-container a .tile-image {
        height: auto; }
  .carousel-product .container-fluid {
    padding: 0; }
    @media (max-width: 543.98px) {
      .carousel-product .container-fluid {
        padding: 0;
        width: 100%; } }
  .carousel-product .slick-slide img {
    width: 100%; }
  .carousel-product .slick-arrow {
    background: transparent;
    opacity: 1;
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    border: none;
    font-size: 0;
    z-index: 1; }
    .carousel-product .slick-arrow:before {
      width: 20px;
      color: #000;
      font-family: "Tkooples";
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      display: inline-block;
      content: "";
      font-size: 20px; }
    .carousel-product .slick-arrow:focus {
      border: none;
      outline: 0; }
    @media (max-width: 839.98px) {
      .carousel-product .slick-arrow {
        top: 37%; } }
  .carousel-product .slick-prev {
    left: 1.5%; }
    .carousel-product .slick-prev:before {
      transform: rotate(180deg); }
  .carousel-product .slick-next {
    right: 1.5%; }

.slider-caroussel {
  margin: 0;
  position: relative; }
  .slider-caroussel .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 112px;
    z-index: 99; }
    .slider-caroussel .slick-dots li {
      margin: 0 4px; }
    .slider-caroussel .slick-dots button {
      display: block;
      width: 8px;
      height: 8px;
      padding: 0;
      border: none;
      border-radius: 100%;
      background-color: #C8C8C8;
      text-indent: -9999px; }
    .slider-caroussel .slick-dots li.slick-active button {
      background-color: #fff; }
    @media (max-width: 839.98px) {
      .slider-caroussel .slick-dots {
        bottom: 87px;
        padding: 0; } }
  .slider-caroussel .image-heading-container {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 54px;
    width: 70%;
    top: auto;
    transform: translateY(0px); }
    @media (max-width: 839.98px) {
      .slider-caroussel .image-heading-container {
        bottom: 48px; } }
    .slider-caroussel .image-heading-container .col-ITC-text p {
      margin-bottom: 0px;
      font-size: 24px;
      font-weight: 700;
      line-height: 18px;
      text-transform: uppercase;
      -webkit-margin-after: 0px;
              margin-block-end: 0px; }
      @media (max-width: 839.98px) {
        .slider-caroussel .image-heading-container .col-ITC-text p {
          font-size: 18px;
          line-height: 12px; } }
    .slider-caroussel .image-heading-container .ITC-cta-label {
      margin-top: 44px; }
      @media (max-width: 839.98px) {
        .slider-caroussel .image-heading-container .ITC-cta-label {
          margin-top: 32px; } }
    .slider-caroussel .image-heading-container.heading-container-slider {
      bottom: 62px; }
      @media (max-width: 839.98px) {
        .slider-caroussel .image-heading-container.heading-container-slider {
          bottom: 54px; } }
